import React, { Suspense } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { OrbitControls } from "@react-three/drei"
import { Canvas } from "@react-three/fiber";
import { House } from "./House"
import '../Component.css/TopHeader.css'
// import bgimage from '../Images/bg-banner-home5.png'
import bgimage from '../Images/bg-banner-.png'
import { Link } from 'react-router-dom';
// import Particles from 'react-particles-js';
import { Donut } from "react-awesome-shapes";
import { FaReadme } from 'react-icons/fa';
import broucherpdf from '../Images/ssibrochurefinal.pdf'

export default function TopHeader() {
    return (
        <>
    
            <Container className='mainbody' style={{ padding: '50px 40px 80px 40px', backgroundImage: `url(${bgimage})`, zIndex: '1', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '100%', fontFamily: 'dm sans', width: '100%' }} id="homeId" fluid>
               <div className='roundcircle '></div>
               <div className='roundball '></div>
               <div className='roundbal2'></div>
               <div className='roundba3'></div>
               <div className='roundb4'></div>
         
               {/* <div className='broucher'>
                <a className='iconBroucher' href={broucherpdf}>Download Our Broucher</a>
               </div> */}
                <Row>
                    <Col md={6} style={{ paddingTop: '25px' }}>
                    <div className='rainsb'>
                                <div className='line1'></div>

                            </div>
                        {/* <p style={{ fontWeight: 'normal', fontSize: '40px', color: '#fff'}}>Find <span style={{ fontSize: '40px', color: '#ffb600', fontWeight: 600 }}>Pandit's</span> &</p>
                        <h1 style={{ fontSize: '45px', color: '#fff', fontWeight: 800 }}>Get Your Puja's Done</h1>

                        <hr style={{ marginTop: '20px', color: '#ffb600 ', borderBottom: '5px solid #ffb600' }}></hr> */}
                        <h1 className='text' style={{ marginTop: '30px', fontSize: '40px', color: '#fff', fontWeight: 800 }}>Emerging Technology With Aspiring Minds</h1>
                        <p style={{ color: '#ecf0f1', fontSize: '12px',marginLeft:'20px' }}>We are a website & app development company that turns your ideas into a new driving force of your business. We keep our process simple, transparent, and customer focused.</p>
                        <Link to='/letsTalk'>
                            <Button style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)',marginLeft:'20px', borderRadius: '30px', border: 'none', marginTop: '10px', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} >Get Started</Button>
                        </Link>
                        {/* <hr style={{ marginTop: '20px', color: '#ffb600 ', borderBottom: '5px solid #ffb600' }}></hr> */}
                    </Col>
                    <Col md={6}>
                    <div className='roundb3'></div>
                            <div className='rainsb'>
                                <div className='line1'></div>
                                <div className='line2'></div>
                                <div className='line3'></div>
                                <div className='line4'></div>

                            </div>
                            
                        <div style={{ width: "100%", height: "60vh", marginTop: "10px", }}>
                            <Canvas>
                                <OrbitControls enableZoom={false} />
                                <directionalLight intensity={0.8} />
                                <ambientLight intensity={0.2} />
                                <Suspense fallback={null}>
                                    <House />
                                </Suspense>
                            </Canvas>

                        </div>
                        {/* <Donut
                            color="#f43f5e"
                            size="120px"
                            style={{ marginRight: "-60px" }}
                            marginLeft="120px"
                            width={['20px', '20px', '30px', '30px']}
                            zIndex={2}
                        /> */}
                    </Col>
                </Row>

            </Container>

        </>
    )
}
