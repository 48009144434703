import { gql } from "@apollo/client";

export const GET_APPLICATIONS = gql `
query Query {
  getApplications {
    id
    fname
    lname
    email
    contact
    roll
    file
    createdDateTime
    status
  }
}
`