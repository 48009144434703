import {Outlet} from 'react-router-dom'

import React from 'react'
import Navigation from './Navigation'

export default function Layout() {
  return (
    <>
    <Navigation/>
     <Outlet/>
    </>
  )
}
