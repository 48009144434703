import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  Outlet
} from "react-router-dom";
import Chatbox from './Components/Chatbox';
import Career from './Components/Career';
import AdminDashboard from './Components/Admin/AdminDashboard';
import AdminLogin from './Components/Admin/AdminLogin';
import About from './Components/About';
import Products from './Components/Products';
import Layout from './Components/Layout';

function App() {
  return (
    <Routes>

      <Route path='/' element={<Layout />} >
        <Route path='/' element={<Home />} />
        <Route path='/letsTalk' element={<Chatbox />} />
        <Route path='/career' element={<Career />} />
        <Route path='/admindashboard' element={<AdminDashboard />} />
        <Route path='/adminlogin' element={<AdminLogin />} />
        <Route path='/about' element={<About />} />
        <Route path='/products' element={<Products />} />
      </Route>
    </Routes>
  );
}

export default App;
