import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function ContactUs() {
  return (
    <>
      <Container>
        <Row>
            <Col md={6}>
            
            </Col>
        </Row>
      </Container>
    </>
  )
}
