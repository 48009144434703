import React from 'react'
import { Col, Container, Image, Nav, Row, Tab } from 'react-bootstrap'
import AllApplications from './AllApplications'
import Navigation from '../Navigation'
import { Navigate } from 'react-router-dom'

export default function AdminDashboard() {

    const token = localStorage.getItem('adminToken')
    if (!token) {
        return <Navigate to="/adminlogin" />
    }


  return (
    <>
    {/* <Navigation /> */}
      <Container style={{paddingTop:'30px'}} fluid>
      <h5 style={{textAlign:'center'}}>All Applications</h5>
      <hr></hr>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">All Applications</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Tab 2</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <AllApplications />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <AllApplications />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
      </Container>
    </>
  )
}
