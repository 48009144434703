import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
// import aboutus from '../Images/about.png'
import aboutus from '../Images/hosting_sec.png'
import svgFile from '../Images/Sprinkle (1).jpg'
import '../Component.css/About.css'

export default function AboutUs() {
  return (
    <>
      <Container style={{backgroundImage: `url(${svgFile})`, backgroundSize: 'cover',marginTop:'30px'}} fluid>
      {/* <h4 style={{color:'#ffffff',textAlign:'center'}}>About Us</h4>
      <hr style={{color:'#fff'}}></hr> */}
        <Row>
            <Col md={6}>
               <Image className='imgmoment mx-auto d-block' src={aboutus} style={{height:'100%',width:'100%',marginTop:'50px'}}></Image>
            </Col>
            <Col md={6}>
            <h5 style={{color:'#feb302',marginTop:'30px',fontWeight:600}}>WHO WE ARE</h5>
            <h1 style={{color:'#ffffff',marginTop:'30px',fontWeight:900}}>We Are <span style={{ color: 'rgb(231,47,235)' }}>All In One</span> IT Solution & Technology Company</h1>
                <p style={{color:'#b4b4b4',marginTop:'30px',fontSize:'15px'}}>Softseekers is a Website, App And Software development company specializing in providing businesses worldwide with custom technology solutions. As a mobile and web-based application development company with skilled software developers, Softseekers combines technological expertise, specific domain experience, and a passion for excellence to deliver enterprise-grade solutions.</p>
                <p style={{color:'#b4b4b4',marginTop:'40px',fontSize:'15px'}}>We develope mobile apps for a range of top platforms, including Android and iOS. We also have extensive knowledge in developing complex web backend and frontend projects. Our primary passion is turning your ideas into game-changing products across global markets. Our team offers a complete range of web & mobile application services, including initial prototyping, UI/UX design, Android / iOS mobile development, as well as application backend and website infrastructure.</p>
            </Col>
        </Row>
      </Container>
   
    </>
  )
}
