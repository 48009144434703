import { gql } from '@apollo/client'

export const SEND_MAIL = gql`
mutation Mutation($mailDetailsInput: mailDetailsInput) {
  createMail(MailDetailsInput: $mailDetailsInput) {
    id
    name
    contact
    email
    requirement
    industry
    budget
    timeline
    createdDateTime
    status
  }
}
`
export const CAREER_APPLICATIONS = gql`
mutation Mutation($applicationsInput: applicationsInput) {
  createApplications(ApplicationsInput: $applicationsInput) {
    id
    fname
    lname
    email
    contact
    roll
    file
    createdDateTime
    status
  }
}
`
export const MUTATION_ADMIN_LOGIN = gql`
mutation Mutation($userName: String, $password: String) {
  adminLogin(userName: $userName, password: $password) {
    adminId
    adminToken
    adminTokenExpire
  }
}
`