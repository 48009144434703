import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import Lottie from 'react-lottie';
import { Link, Navigate } from 'react-router-dom';
import * as animationData from '../../Images/115849-experienced-technical.json'
import Footer from '../Footer';
import Navigation from '../Navigation';
import '../../Component.css/AdminLogin.css'
import { useMutation } from '@apollo/client';
import { MUTATION_ADMIN_LOGIN } from '../../graphql/Mutations';

export default function AdminLogin() {

  const token = localStorage.getItem('adminToken')
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [errorState, setErrorState] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [validated, setValidated] = useState(false);


  const [createAdmin, { loading: createAdminLoading, data }] = useMutation(MUTATION_ADMIN_LOGIN, {
    onError(error) {
      setErrorState(true)
    }
  })


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      createAdmin({
        variables: {
          "userName": `${username}`,
          "password": `${password}`
        }
      })
    }
  };
  if (data) {
    localStorage.setItem("adminToken", data && data.adminLogin.adminToken)
    localStorage.setItem("adminId", data && data.adminLogin.adminId)
    // swal("Welcome", "To Your Dashboard", "success");
    return <Navigate to="/admindashboard" />
  }

  if (token) {
    return <Navigate to="/admindashboard" />
  }


  return (
    <>
      {/* <Navigation /> */}
      <Container style={{ backgroundColor: '#050622', padding: '20px' }} fluid>
        <Row>
          <Col md={6} style={{ paddingTop: '50px' }}>
            <Lottie options={defaultOptions}
              height={'100%'}
              width={'100%'}
            />
          </Col>
          <Col md={6} style={{ paddingTop: '50px' }}>
            <Card className='logindesign'>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <h3 className='headertext'>Login Here</h3>
                <Form.Label className='lable'>Username</Form.Label>
                <Form.Control className='plcehlder' type="text" placeholder="Enter Username" onChange={(e) => setUsername(e.target.value)} />

                <Form.Label className='lable'>Your Password</Form.Label>
                <Form.Control className='plcehlder' type="password" placeholder="Enter Your Password" onChange={(e) => setPassword(e.target.value)} />
                <Link className='linkitem'>Forgot Password?</Link>
                {
                  errorState ?
                    <h1 style={{ fontSize: '15px', textAlign: 'center', color: 'red', marginTop: '20px' }}>Username & Password not match!!!</h1>

                    : ''
                }
                {
                  createAdminLoading ?
                    <Spinner animation="border" role="status" className="mx-auto d-block">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner> :
                    <Button type="submit" className="btntype mx-auto d-block">Login</Button>
                }
              </Form>
            </Card>
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  )
}
