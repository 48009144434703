import { OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import React, { Suspense } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import '../Component.css/OurProcess.css'
import Lottie from 'react-lottie';
// import * as animationData from '../Images/4229-no-data-animation.json'
import * as animationData from '../Images/5fb6bb9b68f703794c60150d_Process.json'
// import * as animationData from '../Images/65680-process-animation.json'

export default function OurProcess() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    return (
        <>
            <Container>
            <h5 style={{color:'#feb302',marginTop:'30px',fontWeight:550}}>OUR  PROCESS</h5>
                <Row>
                    <Col md={12}>
                        <Lottie options={defaultOptions}
                            height={'100%'}
                            width={'100%'}
                        />
                    </Col>
                </Row>

                {/* <Row>
                    <Col md={3}></Col>
                    <Col md={6} style={{ marginTop: '30px' }}>
                        <Card className='mx-auto d-block processcard'>
                            <div className='divCard'>
                                <h4 style={{ textAlign: 'center', fontSize: 14, fontWeight: 700, marginTop: '15px' }}>1. Post your Requirements</h4>
                                <p style={{ textAlign: 'center', fontSize: 12, fontWeight: 500, marginTop: '10px' }}>Post your requirements for any IT Support and we will analyse it.</p>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Card className='mx-auto d-block processcard'>
                            <div className='divCard'>

                                <h4 style={{ textAlign: 'center', fontSize: 14, fontWeight: 700, marginTop: '15px' }}>2. Connect with Us</h4>
                                <p style={{ textAlign: 'center', fontSize: 12, fontWeight: 500, marginTop: '10px' }}>After the analysis of client's requirements. We have to connect in a meet to discuss more about the project.</p>
                            </div>
                        </Card>
                    </Col>
                    <Col md={6} style={{ marginTop: '30px' }}>

                        <Card className='mx-auto d-block processcard'>
                            <div className='divCard'>
                                <h4 style={{ textAlign: 'center', fontSize: 14, fontWeight: 700, marginTop: '15px' }}>3. Finalise the Project</h4>
                                <p style={{ textAlign: 'center', fontSize: 12, fontWeight: 500, marginTop: '10px' }}>After finalise the project with costing and timeline, we will proceed to the agreement.</p>
                            </div>
                        </Card>

                    </Col>
                    <Col md={3}>
                        <Card className='mx-auto d-block processcard'>
                            <div className='divCard'>
                                <h4 style={{ textAlign: 'center', fontSize: 14, fontWeight: 700, marginTop: '15px' }}>4. Approve the Agreement</h4>
                                <p style={{ textAlign: 'center', fontSize: 12, fontWeight: 500, marginTop: '10px' }}>Agreement contain the actual project requirements, consting and exact timeline. </p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6} style={{ marginTop: '10px' }}>
                        <Card className='mx-auto d-block processcard'>
                            <div className='divCard'>
                                <h4 style={{ textAlign: 'center', fontSize: 14, fontWeight: 700, marginTop: '15px' }}>5. Get your Project</h4>
                                <p style={{ textAlign: 'center', fontSize: 12, fontWeight: 500, marginTop: '10px' }}>Get your project (Website/Application/Software etc.) into a given timline and given costing. </p>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}></Col>
                </Row> */}

            </Container>
        </>
    )
}
