import React from 'react'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'
import { FaBitcoin, FaBullhorn, FaCartArrowDown, FaFileInvoice, FaGamepad, FaGlobe, FaLaptopCode, FaMobileAlt, FaRegStickyNote, FaShoppingCart } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import '../Component.css/OurServices.css'
import mobile from '../Images/coding.png'
import laptop from '../Images/devices.png'
import software from '../Images/laptop.png'
import digital from '../Images/demostration.png'
import game from '../Images/console.png'
import block from '../Images/blockchain.png'
import erp from '../Images/erp.png'
import ui from '../Images/design.png'


export default function OurServices() {

    return (
        <>
            <Container style={{marginTop:'50px'}}>
            <h5 style={{color:'#feb302',marginTop:'30px',fontWeight:550}}>OUR SERVICES</h5>

            <Row>
                <Col md={3}>
                    <div className='iconservice mx-auto d-block'>
                    <div className='effectcard'></div>
                    <div className='effectcard2'></div>
                    <div className='effectcard3'></div>
                    <div className='effectcard4'></div>
                    <Image src={mobile} className='iconsss mx-auto d-block'  />
                    </div>
                    <h5 style={{textAlign:'center',color:'#ffffff',marginTop:'10px',fontSize:'13px'}}>Mobile App Development</h5>
                </Col>
                <Col md={3}>
                    <div className='iconservice mx-auto d-block'>
                    <div className='effectcardno1'></div>
                    <div className='effectcardno2'></div>
                    <div className='effectcardno3'></div>
                    <div className='effectcardno4'></div>
                    <Image src={laptop} className='iconsss mx-auto d-block'  />
                    </div>
                    <h5 style={{textAlign:'center',color:'#ffffff',marginTop:'10px',fontSize:'13px'}}>Website Development</h5>
                </Col>
                <Col md={3}>
                    <div className='iconservice mx-auto d-block'>
                    <div className='effectcardno21'></div>
                    <div className='effectcardno22'></div>
                    <div className='effectcardno33'></div>
                    <div className='effectcardno44'></div>
                    <Image src={software} className='iconsss mx-auto d-block'  />
                    </div>
                    <h5 style={{textAlign:'center',color:'#ffffff',marginTop:'10px',fontSize:'13px'}}>Software Development</h5>
                </Col>
                <Col md={3}>
                    <div className='iconservice mx-auto d-block'>
                    <div className='effectcardno31'></div>
                    <div className='effectcardno32'></div>
                    <div className='effectcardno34'></div>
                    <div className='effectcardno35'></div>
                    <Image src={digital} className='iconsss mx-auto d-block'  />
                    </div>
                    <h5 style={{textAlign:'center',color:'#ffffff',marginTop:'10px',fontSize:'13px'}}>Digital & Social Marketing</h5>
                </Col>
          
            </Row>
            <Row>

                <Col md={3}>
                    <div className='iconservice mx-auto d-block'>
                    <div className='effectcardno41'></div>
                    <div className='effectcardno42'></div>
                    <div className='effectcardno45'></div>
                    <div className='effectcardno46'></div>
            
                    <Image src={game} className='iconsss mx-auto d-block'  />
                    </div>
                    <h5 style={{textAlign:'center',color:'#ffffff',marginTop:'10px',fontSize:'13px'}}>Game Development</h5>
                </Col>

                <Col md={3}>
                    <div className='iconservice mx-auto d-block'>
                    <div className='effectcardno51'></div>
                    <div className='effectcardno52'></div>
                    <div className='effectcardno55'></div>
                    <div className='effectcardno56'></div>
                    <Image src={block} className='iconsss mx-auto d-block'  />
                    </div>
                    <h5 style={{textAlign:'center',color:'#ffffff',marginTop:'10px',fontSize:'13px'}}>Blockchain Development</h5>
                </Col>

                <Col md={3}>
                    <div className='iconservice mx-auto d-block'>
                    <div className='effectcardno61'></div>
                    <div className='effectcardno62'></div>
                    <div className='effectcardno65'></div>
                    <div className='effectcardno66'></div>
                    <Image src={erp} className='iconsss mx-auto d-block'  />
                    </div>
                    <h5 style={{textAlign:'center',color:'#ffffff',marginTop:'10px',fontSize:'13px'}}>ERP Development</h5>
                </Col>

                <Col md={3}>
                    <div className='iconservice mx-auto d-block'>
                    <div className='effectcardno71'></div>
                    <div className='effectcardno72'></div>
                    <div className='effectcardno75'></div>
                    <div className='effectcardno76'></div>
                    <Image src={ui} className='iconsss mx-auto d-block'  />
                    </div>
                    <h5 style={{textAlign:'center',color:'#ffffff',marginTop:'10px',fontSize:'13px'}}>UI/UX Design</h5>
                </Col>
          
            </Row>

            </Container>
        </>
    )
}

                {/* <Row>
                    <Col md={4}>

                        <Card className='servicescard'>
                            <div className='textservice'>
                                <h4 className='serviceName'>Website Development</h4>
                                <p style={{ color: '#ffffff',fontSize:'13px' }}>Our custom web development services include both front-end and back-end development. Whether it is enhancing an existing application or architecting an enterprise application, our developers are up for the challenge.</p>
                              
                                <Button className='btnservice'>Learn More</Button>
                            </div>
                        </Card> */}
                        {/* <div className='mx-auto d-block serviceCard'>
                            <div className='serviceicon mx-auto d-block'>
                                <FaMobileAlt className='mx-auto d-block' style={{ color: ' rgb(207,112,0)', height: '45px', width: '45px', marginTop: '18px' }} />
                            </div>
                            <h6 style={{ textAlign: 'center', color: '#0dc9e0', marginTop: '30px' }}>Mobile App Development</h6>
                            <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>Progressive Mobile App is the next best thing in the app experience that we develop. It responds more quickly than a native app. Your business can offer cached app content browsed by users during poor internet connectivity.</p>
                            <Link className='mx-auto d-block' style={{ textAlign: 'center', color: '#0dc9e0', fontSize: '12px',textDecoration:'none' }}>Learn More</Link>
                        </div> */}

                    {/* </Col>
                    <Col md={4}>

                    <Card className='servicescard'>
                            <div className='textservice'>
                                <h4 className='serviceName'>Mobile App Development</h4>
                                <p style={{ color: '#ffffff',fontSize:'13px' }}>Progressive Mobile App is the next best thing in the app experience that we develop. It responds more quickly than a native app. Your business can offer cached app content browsed by users during poor internet connectivity.</p>
                              
                                <Button className='btnservice'>Learn More</Button>
                            </div>
                        </Card> */}

                        {/* <div className='mx-auto d-block serviceCard'>
                            <div className='serviceicon mx-auto d-block'>
                                <FaGlobe className='mx-auto d-block' style={{ color: ' rgb(207,112,0)', height: '45px', width: '45px', marginTop: '18px' }} />
                            </div>
                            <h6 style={{ textAlign: 'center', color: '#0dc9e0', marginTop: '30px' }}>Website Development</h6>
                            <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>Our custom web development services include both front-end and back-end development. Whether it is enhancing an existing application or architecting an enterprise application, our developers are up for the challenge.</p>
                            <Link className='mx-auto d-block' style={{ textAlign: 'center', color: '#0dc9e0', fontSize: '12px', textDecoration: 'none' }}>Learn More</Link>
                        </div> */}

                    {/* </Col>
                    <Col md={4}>

                    <Card className='servicescard'>
                            <div className='textservice'>
                                <h4 className='serviceName'>Software Development</h4>
                                <p style={{ color: '#ffffff',fontSize:'13px' }}>Our Custom Software development solutions that automate time-consuming processes with custom solutions. We not only increase the productivity of your system, but also help to gain quick financial returns.</p>
                              
                                <Button className='btnservice'>Learn More</Button>
                            </div>
                        </Card> */}

                        {/* <div className='mx-auto d-block serviceCard'>
                            <div className='serviceicon mx-auto d-block'>
                                <FaLaptopCode className='mx-auto d-block' style={{ color: ' rgb(207,112,0)', height: '45px', width: '45px', marginTop: '18px' }} />
                            </div>
                            <h6 style={{ textAlign: 'center', color: '#0dc9e0', marginTop: '30px' }}>Software Development</h6>
                            <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>Our Custom Software development solutions that automate time-consuming processes with custom solutions. We not only increase the productivity of your system, but also help to gain quick financial returns.</p>
                            <Link className='mx-auto d-block' style={{ textAlign: 'center', color: '#0dc9e0', fontSize: '12px', textDecoration: 'none' }}>Learn More</Link>
                        </div> */}

                    {/* </Col>
                </Row>
                <Row>
                    <Col md={4}>
                    
                    <Card className='servicescard'>
                            <div className='textservice'>
                                <h4 className='serviceName'>Digital & Social Marketing</h4>
                                <p style={{ color: '#ffffff',fontSize:'13px' }}>Social media marketing is a form of digital marketing that leverages the power of popular social media networks to achieve your marketing and branding goals.</p>
                              
                                <Button className='btnservice'>Learn More</Button>
                            </div>
                        </Card> */}

                        {/* <div className='mx-auto d-block serviceCard'>
                            <div className='serviceicon mx-auto d-block'>
                                <FaBullhorn className='mx-auto d-block' style={{ color: ' rgb(207,112,0)', height: '45px', width: '45px', marginTop: '18px' }} />
                            </div>
                            <h6 style={{ textAlign: 'center', color: '#0dc9e0', marginTop: '30px' }}>Digital & Social Marketing</h6>
                            <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>Social media marketing is a form of digital marketing that leverages the power of popular social media networks to achieve your marketing and branding goals.</p>
                            <Link className='mx-auto d-block' style={{ textAlign: 'center', color: '#0dc9e0', fontSize: '12px', textDecoration: 'none' }}>Learn More</Link>
                        </div> */}

                    {/* </Col>
                    <Col md={4}>

                    <Card className='servicescard'>
                            <div className='textservice'>
                                <h4 className='serviceName'>Game Development</h4>
                                <p style={{ color: '#ffffff',fontSize:'13px' }}>We have an excellent team of web development experts who are constantly experimenting and implementing innovative ideas that will transform website designs into a gold mine for your business.</p>
                              
                                <Button className='btnservice'>Learn More</Button>
                            </div>
                        </Card> */}
                        {/* <div className='mx-auto d-block serviceCard'>
                            <div className='serviceicon mx-auto d-block'>
                                <FaGamepad className='mx-auto d-block' style={{ color: ' rgb(207,112,0)', height: '45px', width: '45px', marginTop: '18px' }} />
                            </div>
                            <h6 style={{ textAlign: 'center', color: '#0dc9e0', marginTop: '30px' }}>Game Development</h6>
                            <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>We have an excellent team of web development experts who are constantly experimenting and implementing innovative ideas that will transform website designs into a gold mine for your business.</p>
                            <Link className='mx-auto d-block' style={{ textAlign: 'center', color: '#0dc9e0', fontSize: '12px', textDecoration: 'none' }}>Learn More</Link>
                        </div> */}

                    {/* </Col>
                    <Col md={4}>

                    <Card className='servicescard'>
                            <div className='textservice'>
                                <h4 className='serviceName'>Blockchain Development</h4>
                                <p style={{ color: '#ffffff',fontSize:'13px' }}>The development of blockchains has been hailed as an excellent method to provide high levels of security, confidentiality, and immutability to businesses Blockchain software development process.</p>
                              
                                <Button className='btnservice'>Learn More</Button>
                            </div>
                        </Card> */}

                        {/* <div className='mx-auto d-block serviceCard'>
                            <div className='serviceicon mx-auto d-block'>
                                <FaBitcoin className='mx-auto d-block' style={{ color: ' rgb(207,112,0)', height: '45px', width: '45px', marginTop: '18px' }} />
                            </div>
                            <h6 style={{ textAlign: 'center', color: '#0dc9e0', marginTop: '30px' }}>Blockchain Development</h6>
                            <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>The development of blockchains has been hailed as an excellent method to provide high levels of security, confidentiality, and immutability to businesses Blockchain software development process.</p>
                            <Link className='mx-auto d-block' style={{ textAlign: 'center', color: '#0dc9e0', fontSize: '12px', textDecoration: 'none' }}>Learn More</Link>
                        </div> */}
                    {/* </Col>
                </Row> */}
                {/* <Row>
                    <Col md={4}>
                        <div className='mx-auto d-block serviceCard'>
                            <div className='serviceicon mx-auto d-block'>
                                <FaShoppingCart className='mx-auto d-block' style={{ color: ' rgb(207,112,0)', height: '40px', width: '40px', marginTop: '18px' }} />
                            </div>
                            <h6 style={{ textAlign: 'center', color: '#0dc9e0', marginTop: '30px' }}>E-Commerce Development</h6>
                            <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>Ecommerce website development is the process of developing the technical aspects of your ecommerce website — how it functions, accepts payments, behaves in response to user action and appears aesthetically.</p>
                            <Link className='mx-auto d-block' style={{ textAlign: 'center', color: '#0dc9e0', fontSize: '12px', textDecoration: 'none' }}>Learn More</Link>
                        </div>

                    </Col>
                    <Col md={4}>
                        <div className='mx-auto d-block serviceCard'>
                            <div className='serviceicon mx-auto d-block'>
                                <FaFileInvoice className='mx-auto d-block' style={{ color: ' rgb(207,112,0)', height: '45px', width: '45px', marginTop: '18px' }} />
                            </div>
                            <h6 style={{ textAlign: 'center', color: '#0dc9e0', marginTop: '30px' }}>ERP Development</h6>
                            <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>An ERP system is an integrated platform for managing large amounts of data in the production and supply chains. The system is divided into numerous modules that handle finance, accounting, procurement, human resources, manufacturing and delivery, which allows businesses to encompass and automate all processes.</p>
                            <Link className='mx-auto d-block' style={{ textAlign: 'center', color: '#0dc9e0', fontSize: '12px', textDecoration: 'none' }}>Learn More</Link>
                        </div>

                    </Col>
                    <Col md={4}>
                        <div className='mx-auto d-block serviceCard'>
                            <div className='serviceicon mx-auto d-block'>
                                <FaRegStickyNote className='mx-auto d-block' style={{ color: ' rgb(207,112,0)', height: '45px', width: '45px', marginTop: '18px' }} />
                            </div>
                            <h6 style={{ textAlign: 'center', color: '#0dc9e0', marginTop: '30px' }}>Ui/Ux Design</h6>
                            <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>We offer easily deployable solutions for enterprises that gather data from sensors to provide actionable business insights. We serve an interracial portfolio of products, IT solutions, ML services, IoT, amongst others, to improve startups reimagine their industries for the digital era</p>
                            <Link className='mx-auto d-block' style={{ textAlign: 'center', color: '#0dc9e0', fontSize: '12px', textDecoration: 'none' }}>Learn More</Link>
                        </div>

                    </Col>
                </Row> */}
           
