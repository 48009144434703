import { useQuery } from '@apollo/client'
import React from 'react'
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import { FaRegFilePdf } from 'react-icons/fa'
import { GET_APPLICATIONS } from '../../graphql/Query'

export default function AllApplications() {

    const {data:applicationData,loading:dataLoading} = useQuery(GET_APPLICATIONS)
    console.log("applicationData",applicationData)
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Role</th>
                                    <th>View Resume</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                dataLoading?
                                <Spinner animation="border" variant="primary" />
                                :
                                applicationData && applicationData.getApplications.slice().reverse().map(userData =>

                                <tr>
                                    <td>{userData.fname} {userData.lname}</td>
                                    <td>{userData.email}</td>
                                    <td>{userData.contact}</td>
                                    <td>{userData.roll}</td>
                                    <td><a style={{ textDecoration: 'none' }} title="View" href={`https://spudme.s3.ap-south-1.amazonaws.com/${userData.file}`} target="_blank"> <FaRegFilePdf style={{ height: '22px', width: '25px', color: '#f72015' }} /></a></td>
                                </tr>

                                )
                            }
                    
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
