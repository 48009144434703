import React, { useState } from 'react'
import { Accordion, Button, Card, Col, Container, Form, Image, Modal, Row, Spinner } from 'react-bootstrap'
import Footer from './Footer'
import Navigation from './Navigation'
import '../Component.css/Career.css'
import Lottie from 'react-lottie';
import * as animationData from '../Images/63258-career-animation.json'
import { useMutation } from '@apollo/client'
import { CAREER_APPLICATIONS } from '../graphql/Mutations'
import swal from 'sweetalert';
import AWS from "aws-sdk";
import uniqid from "uniqid";
import { GET_APPLICATIONS } from '../graphql/Query'

const ID = "AKIA6GB4RFKTM63VVHEK";
const SECRET = "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";
const BUCKET_NAME = "spudme";

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});

export default function Career() {

    {
        window.scrollTo(0, 0)
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [validated, setValidated] = useState(false);
    const [fname, setFname] = useState()
    const [lname, setLname] = useState()
    const [email, setEmail] = useState()
    const [contact, setContact] = useState()
    const [file, setFile] = useState()
    const [roll, setRoll] = useState()
    
    function handleShowClick (data){
        setRoll(data)
        handleShow()
    }

    const [createApplications, { loading: applicationLoading }] = useMutation(CAREER_APPLICATIONS,{
        refetchQueries:[
            GET_APPLICATIONS
        ]
    })

    function handleSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(false);
            event.preventDefault();
            try {
                const uniqueId = uniqid();
                var regex = new RegExp('[^.]+$');
                var extension = file.name.match(regex);
                var uniqueFileName = 'ssiresumedoc' + '-' + uniqueId + '.' + extension[0];
                const params = {
                    Bucket: BUCKET_NAME,
                    Key: uniqueFileName,
                    Body: file,
                };
                s3.upload(params, function (err, data) {
                    if (err) {
                        throw err;
                    } else {
                        createApplications({
                            variables: {
                                "applicationsInput": {
                                    "fname": `${fname}`,
                                    "lname": `${lname}`,
                                    "email": `${email}`,
                                    "contact":`${contact}`,
                                    "roll": `${roll}`,
                                    "file": uniqueFileName
                                }
                            }
                        })
                        swal({
                            title: "Thanks For Applying!!",
                            text: "We Will Contact You Soon!",
                            icon: "success",
            
                        })
                        setFname('')
                        setLname('')
                        setEmail('')
                        setContact('')
                        setFile('')
                        handleClose()
                    }
                });
            }
            catch (err) {
                swal({
                    title: 'Error!!!',
                    text: 'Error please try again',
                    icon: 'error',
                });
                console.log(err);
                console.log("uniqueFileName",uniqueFileName);
            }

        }
    }

    
    return (
        <>
            {/* <Navigation /> */}
            <Container style={{ backgroundColor: '#050622', padding: '40px' }} fluid>
            <h5 style={{ color: '#feb302', marginTop: '30px', fontWeight: 550 }}>CAREER PAGE</h5>

                <Row>
                    <Col md={6} >
                        <div className='mx-auto d-block' style={{ height: '250px', width: '300px', marginTop: '20px' }}>
                            <Lottie options={defaultOptions}
                                height={'100%'}
                                width={'100%'}
                            />
                        </div>
                    </Col>

                    <Col md={6} >
                        <Accordion defaultActiveKey="0" >
                            <Accordion.Item eventKey="0" style={{ marginTop: '30px' }} >
                                <Accordion.Header>1. MERN Developer</Accordion.Header>
                                <Accordion.Body style={{fontSize:13}}>
                                    <p>Experience: 0-1 Year.</p>
                                    <p>Location : Remote/Onsite</p>
                                    <br></br>
                                    <p>Roles and Responsibilities:</p>
                                    <p>1) Create website UI using HTML, CSS, Javascript, ReactJS</p>
                                    <p>2) Create GraphQL APIs with NodeJS</p>
                                    <p>3) Architect scalable web architectures</p>
                                    <p>4) Integration of MongoDB cloud database</p>
                                    <p>5) Using AWS services with project</p>
                                    <p>6) Be responsible to change requests and feature requests</p>
                                    <p>7) Write code that is cross-platform and cross-device compatible</p>
                                    <p>8) Ability to learn new technologies quickly</p>
                                    <br></br>
                                    <p>Desire Candidate Profile:</p>
                                    <p>1) Fluent knowledge of latest HTML/CSS and standards and best practices</p>
                                    <p>2) Working knowledge of JavaScript, NodeJS ReactJS and API integration Working knowledge of Node.js and Express</p>
                                    <p>3) Solid Understanding of HTTP protocol and how server-side code is processed on the client</p>
                                    <p>4) Working knowledge of front end optimization and performance techniquesObsession with lean, clean and organized front-end code</p>
                                    <p>5) Cross-browser development and troubleshooting</p>
                                    <p>6) Experience building Responsive websites for web, tablet and mobile devices</p>
                                    <p>7) Able to handle multiple projects and competing deadlines</p>
                                    <p>8) Good understanding of overall web design including basic usability, accessibility, industry standards, architecture, and navigation.</p>
                                    <Button onClick={()=>handleShowClick('MERN Developer')} style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', marginLeft: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }}>Apply Now</Button>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2. React Native Developer</Accordion.Header>
                                <Accordion.Body style={{fontSize:13}}>
                                    <p>Experience: 0-1 Year.</p>
                                    <p>Location : Remote/Onsite</p>
                                    <br></br>
                                    <p>Roles and Responsibilities:</p>
                                    <p>1) Create UI/UX using React Native</p>
                                    <p>2) Create GraphQL APIs with NodeJS</p>
                                    <p>3) Integration of MongoDB cloud database</p>
                                    <p>4) Using AWS services with project</p>
                                    <p>5) Be responsible to change requests and feature requests</p>
                                    <p>6) Write code that is cross-platform and cross-device compatible</p>
                                    <p>7) Ability to learn new technologies quickly</p>
                                    <br></br>
                                    <p>Desire Candidate Profile:</p>
                                    <p>1) Fluent knowledge of React Native and standards and best practices</p>
                                    <p>2) Working knowledge of JavaScript, NodeJS ReactJS and API integration Working knowledge of Node.js and Express</p>
                                    <p>3) Solid Understanding of HTTP protocol and how server-side code is processed on the client</p>
                                    <p>4) Working knowledge of front end optimization and performance techniquesObsession with lean, clean and organized front-end code</p>
                                    <p>5) Cross-browser development and troubleshooting</p>
                                    <p>6) Able to handle multiple projects and competing deadlines</p>
                                   
                                    <Button onClick={()=>handleShowClick('React Native Developer')} style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', marginLeft: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }}>Apply Now</Button>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <Footer />
            </Container>
            <Modal show={show} onHide={handleClose}>

                <Modal.Body>
                    <h5 style={{ textAlign: 'center' }}>Apply Here</h5>
                    <hr></hr>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="First name"
                                    onChange={(e) => setFname(e.target.value)}
                                    value={fname}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid First Name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Last name"
                                    onChange={(e) => setLname(e.target.value)}
                                    value={lname}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Last Name.
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email"
                                 placeholder="Enter Email"
                                 onChange={(e) => setEmail(e.target.value)}
                                    value={email} 
                                 required />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Email.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Contact</Form.Label>
                                <Form.Control type="text" placeholder=" Enter Contact" required 
                                    onChange={(e) => setContact(e.target.value)}
                                    value={contact}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid contact.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustom03">
                                <Form.Label>Upload Resume</Form.Label>
                                <Form.Control type="file" name="file" required accept=".pdf,.docx" 
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid city.
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                        <Form.Group className="mb-3">
                            <Form.Check
                                required
                                label="Agree to terms and conditions"
                                feedback="You must agree before submitting."
                                feedbackType="invalid"
                            />
                        </Form.Group>
                        {
                            applicationLoading ?
                                <Spinner animation="border" variant="success" />
                                :
                                <Button className='mx-auto d-block' type="submit" style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', marginLeft: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }}>Submit form</Button>
                        }
                    </Form>

                </Modal.Body>

            </Modal>
        </>
    )
}
