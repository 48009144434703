import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import logo from '../Images/WhatsApp_Image_2023-01-23_at_4.39.38_PM-removebg-preview.png'
import { Link } from 'react-router-dom'
import { Link as Link1 } from "react-scroll";

export default function Footer() {
    return (
        <>
            <Container style={{ marginTop: '100px', padding: '20px' }}>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <div className='mx-auto d-block' style={{ height: '120px', width: '230px' }}>
                            <Image src={logo} style={{ height: '100%', width: '100%' }}></Image>

                        </div>
                        <h6 style={{ color: '#fff', textAlign: 'center' }}>Softseekers Infotech Private Limited</h6>
                        <hr className='mx-auto d-block' style={{ color: '#fff', width: '100%' }}></hr>
                        <Row>
                            <div style={{ display: 'flex' }}>

                                <Col md={3}>
                                    <Link1 to="homeId" className='mx-auto d-block' spy={true} offset={-100} duration={500} style={{cursor:'pointer', textAlign: 'center', color: '#fff',textDecoration:'none' }}>Home <span style={{ marginLeft: '10px', marginRight: '5px' }}>|</span></Link1>
                                </Col>
                                <Col md={3}>
                                    <Link  to='/about' style={{ textAlign: 'center', color: '#fff',textDecoration:'none' }}>AboutUs <span style={{ marginLeft: '10px', marginRight: '5px' }}>|</span></Link>
                                </Col>
                                <Col md={3}>
                                    <Link1 to="portId" spy={true} offset={-100} duration={500} style={{ cursor:'pointer',textAlign: 'center', color: '#fff' }}>Portfolio <span style={{ marginLeft: '10px', marginRight: '5px' }}>|</span></Link1>
                                </Col>
                                <Col md={3}>
                                    <Link to='/letsTalk' style={{ textAlign: 'center', color: '#fff',textDecoration:'none' }}>ContactUs </Link>
                                </Col>
                            </div>
                        </Row>
                        <h6 style={{ color: '#fff', textAlign: 'center', marginTop: '20px' }}>Contact No.: +91-9151485749</h6>
                        <h6 style={{ color: '#fff', textAlign: 'center', marginTop: '20px' }}>Address: Qubikals Co-working Space, 13, Chandrika Society, Phase 1, Rohit Nagar, Bawadiya Kalan, Bhopal, Madhya Pradesh 462039</h6>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <h6 style={{ color: '#fff', textAlign: 'center', marginTop: '20px', fontSize: '12px' }}>© Copyright 2023 | All rights reserved. Developed & maintained by <a href='https://softseekersinfotech.com/' style={{ textDecoration: 'none', color: '#fff' }}>Softseekers Infotech Private Limited</a></h6>

            </Container>
        </>
    )
}
