import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import Chatbot from 'react-best-chatbot';
import Navigation from './Navigation';
import Lottie from 'react-lottie';
import * as animationData from '../Images/Comp 1.json'
import Footer from '../Components/Footer.js'
import botImage from '../Images/WhatsApp_Image_2023-01-23_at_4.39.38_PM-removebg-preview.png'
import { FaPaperPlane, FaTimes, FaUndo } from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import { SEND_MAIL } from '../graphql/Mutations';

export default function Chatbox() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [createMail] = useMutation(SEND_MAIL)



    // const[text,setText] =useState([])
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const defaultOptions1 = {
        header: <span style={{ color: "#000" }}>SSi Bot</span>,
        open: true,
        endContent: "Bye 👋",
        inputPlaceholder: "Type in your message...",
        botAvatarSrc: botImage,
        messageDelay: 500,
        sendComponentFunction: () => <FaPaperPlane style={{ color: 'blue' }} />,
        loadingComponent: "Loading...",
        refreshComponent: <FaUndo />,
        closeComponent: <FaTimes style={{ color: '#c0392b' }} />,
        openingCallback: () => { },
        closingCallback: () => { },
        endingCallback: (answers) => { sendmailHandle(answers) },
        sendingMessageCallback: () => { },
    };



    function sendmailHandle(answers) {
        createMail({
            variables: {
                "mailDetailsInput": {
                    "name": `${answers.values.name}`,
                    "contact": `${answers.values.contact}`,
                    "email": `${answers.values.email}`,
                    "requirement": `${answers.values.requirement.content}`,
                    "industry": `${answers.values.Industry.content}`,
                    "budget": `${answers.values.budget.content}`,
                    "timeline": `${answers.values.timing.content}`
                }
            }
        })
    }


    const theme = {
        primaryColor: "#FFFFFF",
        secondaryColor: "#7b68ee",
        textPrimaryColor: "#333333",
        textSecondaryColor: "#FFFFFF",
        containerStyle: {
            width: '100%',


        }
    };



    const steps = [
        {
            id: 1,
            content: "Hi, Welcome To softseekers!!!",
            goTo: "requirement",
        },
        {
            id: "requirement",
            content: "Please select your requirement",
            options: [
                {
                    content: "Website 🌐",
                    value: 1,
                    goTo: "Industry",
                },
                {
                    content: "Mobile App 📱",
                    value: 2,
                    goTo: "Industry",
                },
                {
                    content: "Mobile App & Website 📱🌐",
                    value: 3,
                    goTo: "Industry",
                },
                {
                    content: "Custom Software🌐",
                    value: 3,
                    goTo: "Industry",
                },
                {
                    content: "Digital & Social Media Marketing 📱🌐",
                    value: 4,
                    goTo: "Industry",
                },
            ],
        },
        {
            id: "Industry",
            content: "Please Select Your Industry",
            options: [
                {
                    content: "Fashion And Lifestyle🥼",
                    value: 11,
                    goTo: "budget",
                },
                {
                    content: "Food And Beverages🥗",
                    value: 12,
                    goTo: "budget",
                },
                {
                    content: "HeathCare💉",
                    value: 13,
                    goTo: "budget",
                },
                {
                    content: "Real State🏡",
                    value: 14,
                    goTo: "budget",
                },
                {
                    content: "E-Commerce🛒",
                    value: 15,
                    goTo: "budget",
                },
                {
                    content: "Education & Learning📕",
                    value: 16,
                    goTo: "budget",
                },
                {
                    content: "Manufacturing 🏗",
                    value: 17,
                    goTo: "budget",
                },
                {
                    content: "Other",
                    value: 18,
                    goTo: "budget",
                },
            ],
        },
        {
            id: "budget",
            content: "Please Select Your Budget",
            options: [
                {
                    content: "0-10k",
                    value: 21,
                    goTo: "timing",
                },
                {
                    content: "10k-50k",
                    value: 22,
                    goTo: "timing",
                },
                {
                    content: "50k-1L",
                    value: 23,
                    goTo: "timing",
                },
                {
                    content: "1L-5L",
                    value: 24,
                    goTo: "timing",
                },
                {
                    content: "5L-10L",
                    value: 25,
                    goTo: "timing",
                },
                {
                    content: "above 10L",
                    value: 26,
                    goTo: "timing",
                },
            ],
        },
        {
            id: "timing",
            content: "How soon do you require this project?",
            options: [
                {
                    content: "15 Days",
                    value: 31,
                    goTo: "name",
                },
                {
                    content: "1 Month",
                    value: 32,
                    goTo: "name",
                },
                {
                    content: "1-3 months",
                    value: 33,
                    goTo: "name",
                },
                {
                    content: "3-6 months",
                    value: 34,
                    goTo: "name",
                },
                {
                    content: "6-9 months",
                    value: 35,
                    goTo: "name",
                },
                {
                    content: "9-12 months",
                    value: 36,
                    goTo: "name",
                },
                {
                    content: "> 1 year",
                    value: 37,
                    goTo: "name",
                },
            ],
        },
        {
            id: "name",
            content: "Ok! please Enter your Name",
            receiveInput: true,
            goTo: "contact",
        },
        {
            id: "contact",
            content: "Ok! please Enter Contact No.",
            receiveInput: true,
            goTo: "email",
        },
        {
            id: "email",
            content: "Ok! please Enter Email",
            receiveInput: true,
            goTo: 7,
        },
        {
            id: 7,
            content: "Thank You for connecting with us😊 We will contact you as soon as possible!!!",
            end: true,
        },
    ];


    return (
        <>
            {/* <Navigation /> */}
            <Container style={{ background: '#050622', padding: '40px' }} fluid>
                <Row>
                    <Col md={6}>
                        <Lottie options={defaultOptions}
                            height={350}
                            width={350}
                        />
                        <h6 style={{ textAlign: 'center', color: '#fff' }}>Grow Your <span style={{ color: '#feb302', fontWeight:500 }}>Business</span> With Us<span style={{ color: '#feb302', fontWeight:500 }}>.</span></h6>
                        <hr style={{ color: '#fff' }}></hr>
                    </Col>
                    <Col md={6}>

                        <Chatbot steps={steps} theme={theme} options={defaultOptions1} />
                    </Col>
                </Row>
                <Footer />
            </Container>
        </>
    )
}
