/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: e-toma (https://sketchfab.com/e-toma)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/sketchbot-37c871cbd9174f67bda4c10d19a97ed0
title: Sketchbot
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function House(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/house.gltf')
  const { actions } = useAnimations(animations, group)
  useEffect(()=>{
  console.log(actions)
  actions.Allert.play();
  actions.Base.play();
  actions.Computation.play();
 
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]} scale={0.18}>
          <group name="ca242c97b9dd43a6bdc52916c4a7aa5bfbx" rotation={[Math.PI / 2, 0, 0]}>
            <group name="Object_2">
              <group name="RootNode">
                <group name="BOT" position={[-1.76, -15.90, 1.23]}>
                  <group name="Object_5">
                    <primitive object={nodes._rootJoint} />
                    <skinnedMesh name="Object_10" geometry={nodes.Object_10.geometry} material={materials.Merged_A7_export} skeleton={nodes.Object_10.skeleton} />
                    <skinnedMesh name="Object_8" geometry={nodes.Object_8.geometry} material={materials.Nuovo} skeleton={nodes.Object_8.skeleton} morphTargetDictionary={nodes.Object_8.morphTargetDictionary} morphTargetInfluences={nodes.Object_8.morphTargetInfluences} />
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/house.gltf')
