import React from 'react'
import { Button, Card, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import roomsnearyou from '../Images/Screenshot (13).png'
import bharatpharma from '../Images/Screenshot (12).png'
import spudme from '../Images/Screenshot (11).png'
import bharp from '../Images/bharp.png'
import scrennwork from '../Images/screen.png'
import pandit from '../Images/pandit.png'
import royalpalac from '../Images/royalpalace.png'
import bhgyalagan from '../Images/Screenshot (22).png'
import speeasy from '../Images/Screenshot (23).png'
import hungerstreet from '../Images/Screenshot (24).png'
import ebm from '../Images/Screenshot (26).png'
import gopalabuilder from '../Images/Screenshot (25).png'

export default function PortfolioSlider() {

    const settings = {

        className: "center",
        infinite: true,
        slidesToShow: 3,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2000,

        afterChange: function (index) {

        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div>

                <Slider {...settings}>
                    <div style={{ backgroundColor: "#fff" }}>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={roomsnearyou} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Rooms Near You</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>List your property in RoomsNearYou and reach thousands of peoples who need the rooms.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://roomsnearyou.in/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={bharatpharma} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Bharat Pharma</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>Bharat Pharma is one of the best online medical stores offering genuine medicines at very affordable rates.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://bharatpharma.in/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={spudme} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Spud Me</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>we make it easy to say Hello! A digital profile that trumps the traditional business cards.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://spudme.uk/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={bharp} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>B-Harp</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>B+Harp is an integrated health care system of hospitals, neighborhood health centers, long-term care facilities and home care.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://bharp.in/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={scrennwork} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Screen Work</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>Screenwork Institute of Animation and Multimedia Create Your Bright Future with us, Learn the High-Quality Education in Multimedia.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://www.screenwork.in/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={pandit} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Pandit Bulao</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>Pandit Bulao website is online solution for book a pandit.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://panditbulao.com/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={royalpalac} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Hotel Royal Palace</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>The Hotel offers some of the city’s finest accommodation, dining experiences, and comprehensive conferencing and banqueting facilities. </p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://hotelroyalpalacesagar.com/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={bhgyalagan} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Byaah Lagan</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>Byaah Lagan website provides you service to find your partner as you wish.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://byaahlagan.com/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={speeasy} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Speeasy</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>Let's delever anything and everything on same day at flate rate rs.33 to rs.59.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://www.speeasyy.com/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={hungerstreet} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Sattu Hunger Street</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>Hunger Street combines flavors & inspiration from the Far East & the West to create what we think is the best! Home to the original recipes.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://sattushungerstreet.in/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={ebm} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>EBM India</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>Know you true profits on Meesho.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://ebmindia.com/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ margin: '10px' }}>
                            <div className="boxPortfolio">
                                <img src={gopalabuilder} style={{ width: "100%", height: "100%" }} />
                                <div className="childPortfolio">
                                    <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Gopala Builders</h4>
                                    <p style={{ color: "#fff", fontSize: "12px" }}>Gopala Builders Infrastructure provides you the wide variety of the custruction services like Real Estate, Interior and Exterior Design.</p>
                                    <Button target='_blank' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} href='https://gopalabuilders.com/'>Visit Website</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>

        </>
    )
}
