import React from 'react'
import { Col, Container, Row, Image, Button } from 'react-bootstrap'
import aboutus from '../Images/hosting_sec.png'
import bgimaGE from '../Images/bg-art-6.png'
import Lottie from 'react-lottie';
import * as animationData from '../Images/118785-responsive-website-design.json'
import * as animationData2 from '../Images/125363-application.json'
import { Link } from 'react-router-dom';
import software1 from "../SoftwareFile/software1.exe"
import software2 from "../SoftwareFile/software2.exe"
export default function Products() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: animationData2,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Container style={{ backgroundColor: '#050622', padding: '30px' }} fluid>
            <div className='aboutbg'></div>
            <div className='  d-none d-lg-block'></div>
            <div className='d-none d-lg-block' style={{ height: '350px', width: '300px', position: 'absolute', marginLeft: '70%' }}>

                <Image src={bgimaGE} style={{ height: '100%', width: '100%' }}></Image>
            </div>
            <div className='abouttxt'>
                <h3 style={{ color: '#ffffff', textAlign: 'center', letterSpacing: '3px' }}>Discover Our Cutting-Edge Software Suite for Seamless  <span style={{ color: '#feb302', fontWeight: 900 }}> Digital Excellence.</span> </h3>
                <p style={{ color: '#ffffff', textAlign: 'center', letterSpacing: '1px', margin: '20px 50px 5px 50px' }}>
                    On our website, we proudly showcase a diverse array of cutting-edge software products designed to meet your varied needs. Explore our innovative solutions that seamlessly blend functionality and user-friendly interfaces to enhance your digital experience. From robust project management tools that streamline workflow to intuitive design software that unleashes creativity, our product lineup caters to professionals and enthusiasts alike. Dive into the world of efficient data management with our specialized software, or elevate your business with powerful analytics tools that provide actionable insights. Whether you're a tech enthusiast, a creative professional, or a business owner, our curated selection of software products is crafted to empower and inspire. Browse through our catalog to discover the perfect solutions that will elevate your digital endeavors to new heights.</p>

                <Row>
                    <Col md={6} style={{ marginTop: '40px' }}>
                        <Lottie options={defaultOptions}
                            height={350}
                            width={350}
                        />
                    </Col>
                    <Col md={6} style={{ marginTop: '100px' }}>
                        <h3 style={{ color: '#ffffff', letterSpacing: '3px' }}>Jwellery Shop Management<span style={{ color: '#feb302', fontWeight: 900 }}> Software</span></h3>
                        <p style={{ color: '#ffffff', marginRight: '4px' }}>
                            🌟 Elevate your jewelry business with SparkleSuite, a revolutionary all-in-one software solution. Seamlessly handle complex calculations, from gemstone pricing to intricate designs, while effortlessly managing your inventory. SparkleSuite securely stores customer data, providing a personalized experience, and offers cloud-based accessibility for on-the-go management. With robust analytics, make informed decisions to optimize your collection and stay ahead in the competitive world of jewelry. Discover the future of jewelry management and customer engagement – SparkleSuite, where precision meets elegance. 💎✨
                        </p>
                        <a href={software1} download >

                            <Button > Download </Button>
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} style={{ marginTop: '100px' }}>
                        <h3 style={{ color: '#ffffff', letterSpacing: '3px', marginLeft: '40px' }}>JewelFlow: Precise  <span style={{ color: '#feb302', fontWeight: 900 }}> Jewelry Inventory Management Software</span></h3>
                        <p style={{ color: '#ffffff', marginLeft: '40px' }}>
                            Introducing our advanced Jewelry Inventory Manager, a powerful boolean-based software tailored for jewelers. Effortlessly record and manage gold and silver sales transactions, along with streamlined tracking of exchanges. Simplify your jewelry business operations with precision and efficiency, ensuring accurate records and seamless transactions. Stay in control of your inventory and enhance customer satisfaction with our intuitive software designed to meet the unique needs of the jewelry industry.
                        </p>
                        <a href={software2} download >

                            <Button style={{marginLeft: '40px'}}> Download </Button>
                        </a>
                    </Col>
                    <Col md={6} style={{ marginTop: '40px' }}>
                        <div className='mx-auto d-block' style={{ height: '350px', width: '300px', position: 'absolute' }}>

                            <Image src={bgimaGE} style={{ height: '100%', width: '100%' }}></Image>
                        </div>
                        <Lottie options={defaultOptions2}
                            height={350}
                            width={350}
                            style={{ position: 'relative' }}
                        />
                    </Col>
                </Row>

            </div>

        </Container>
    )
}
