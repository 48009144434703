import React from 'react'
import { Button, Container, Image, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import logo from '../Images/WhatsApp_Image_2023-01-23_at_4.39.38_PM-removebg-preview.png'
import { FaBars, FaMoon, FaSun } from "react-icons/fa";
import { Link } from 'react-router-dom';
import '../Component.css/Navigation.css'
import { Link as Link1 } from "react-scroll";

export default function Navigation() {
    // const themeChange = () => {
    //     alert("hey!! light mode active very soon...😊")
    // }


    const token = localStorage.getItem('adminToken')
    function logoutHandle() {
        localStorage.removeItem('adminToken')
        localStorage.removeItem('adminId')
        window.location.reload()
    }

    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="dark" bg="#050622" sticky="top" style={{ backgroundColor: '#020f18' }} >
                <Container>
                    <Navbar.Brand>
                        <Link to='/'>

                            <Image src={logo} style={{ height: '60px', width: '120px' }}></Image>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" ><FaBars style={{ color: '#fff' }} /></Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Link to="/" className="titleNavbar">Home</Link>
                            <Link to="/about" className="titleNavbar">About Us</Link>
                            <Link to="/products" className="titleNavbar">Our Products</Link>
                            <Link1 to="portId" spy={true} offset={-100} duration={500} style={{cursor:'pointer'}} className="titleNavbar">Portfolio</Link1>
                            {/* <Link to="/" className="titleNavbar">Our Team</Link> */}
                            <Link1 to="testId" spy={true} offset={-100} duration={500} style={{cursor:'pointer'}} className="titleNavbar">Testimonials</Link1>
                            <Link to="/career" className="titleNavbar">Career</Link>
                            {
                                token ?
                                    <>
                                        <Link to="/admindashboard" className="titleNavbar" >Dashboard</Link>
                                        <Link to="/" className="titleNavbar" onClick={() => logoutHandle()}>Logout</Link>
                                    </>
                                    : ''
                            }
                            <br></br>
                            <Link to='/letsTalk' style={{ textDecoration: 'none' }}>
                                <Button style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', marginLeft: '30px', border: 'none', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }}>Lets Talk</Button>

                            </Link>
                            {/* <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled"><h6 style={{ fontSize: '12px' }}>Switch to light mode</h6></Tooltip>}>
                                <span className="d-inline-block">
                                    <FaSun className="titleNavbar" style={{ marginLeft: '20px' }} onClick={() => themeChange()} />
                                </span>
                            </OverlayTrigger> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}
