import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import "../Component.css/Portfolio.css"
import Slider from "react-slick";
import PortfolioSlider from './PortfolioSlider';

export default function Portfolio() {


    return (
        <>
            <Container style={{ marginTop: '30px' }} id="portId">
                <h5 style={{ color: '#feb302', marginTop: '30px', fontWeight: 500 }}>PORTFOLIO</h5>
                <Row style={{ marginTop: '20px' }}>
                    <Col md={12}>
                        <PortfolioSlider />
                    </Col>
                </Row>


            </Container>
        </>
    )
}


{/* <Col md={4} style={{ padding: '20px' }}>
<div className="boxPortfolio">
    <img src="https://cdn.dribbble.com/users/1921252/screenshots/14096345/free-website-branding-laptop-mockup_4x.jpg" style={{ width: "100%", height: "100%" }} />
    <div className="childPortfolio">
        <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Ecommerce Website</h4>
        <p style={{ color: "#fff", fontSize: "12px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
    </div>
</div>
</Col>
<Col md={4} style={{ padding: '20px' }}>
<div className="boxPortfolio">
    <img src="https://cdn.dribbble.com/users/1921252/screenshots/14096345/free-website-branding-laptop-mockup_4x.jpg" style={{ width: "100%", height: "100%" }} />
    <div className="childPortfolio">
        <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Ecommerce Website</h4>
        <p style={{ color: "#fff", fontSize: "12px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
    </div>
</div>
</Col>
<Col md={4} style={{ padding: '20px' }}>
<div className="boxPortfolio">
    <img src="https://cdn.dribbble.com/users/1921252/screenshots/14096345/free-website-branding-laptop-mockup_4x.jpg" style={{ width: "100%", height: "100%" }} />
    <div className="childPortfolio">
        <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Ecommerce Website</h4>
        <p style={{ color: "#fff", fontSize: "12px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
    </div>
</div>
</Col>

</Row>
<Row>
<Col md={4} style={{ padding: '20px' }}>
<div className="boxPortfolio">
    <img src="https://cdn.dribbble.com/users/1921252/screenshots/14096345/free-website-branding-laptop-mockup_4x.jpg" style={{ width: "100%", height: "100%" }} />
    <div className="childPortfolio">
        <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Ecommerce Website</h4>
        <p style={{ color: "#fff", fontSize: "12px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
    </div>
</div>
</Col>
<Col md={4} style={{ padding: '20px' }}>
<div className="boxPortfolio">
    <img src="https://cdn.dribbble.com/users/1921252/screenshots/14096345/free-website-branding-laptop-mockup_4x.jpg" style={{ width: "100%", height: "100%" }} />
    <div className="childPortfolio">
        <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Ecommerce Website</h4>
        <p style={{ color: "#fff", fontSize: "12px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
    </div>
</div>
</Col>
<Col md={4} style={{ padding: '20px' }}>
<div className="boxPortfolio">
    <img src="https://cdn.dribbble.com/users/1921252/screenshots/14096345/free-website-branding-laptop-mockup_4x.jpg" style={{ width: "100%", height: "100%" }} />
    <div className="childPortfolio">
        <h4 style={{ color: "#fff", fontWeight: "700", fontSize: "20px" }}>Ecommerce Website</h4>
        <p style={{ color: "#fff", fontSize: "12px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
    </div>
</div>
</Col> */}
