import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FaCity, FaGamepad, FaGraduationCap, FaHandHoldingMedical, FaLandmark, FaLuggageCart, FaMicrochip, FaMugHot, FaPlaneDeparture, FaSchool, FaShoppingCart, FaTools } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import '../Component.css/Industry.css'

export default function Industry() {

    const [detailsName, setDetailsName] = useState('')

    function detailsHandle(data) {
        setDetailsName(data)
    }

    return (
        <>
            <Container style={{ marginTop: '50px' }}>
                <h5 style={{ color: '#feb302', marginTop: '30px', fontWeight: 550 }}>INDUSTRIES</h5>
                <hp style={{ color: 'gray', marginTop: '10px' }}>Here are some of the industries and domains that Softseekers has catered to</hp>

                <Row>
                    <Col md={6}>
                        {
                            detailsName === '' ?
                                <>
                                    <h5 style={{ color: '#ffffff', textAlign: 'center', marginTop: '50px' }}>Banking & Finance</h5>
                                    <hr style={{ color: '#ffffff' }}></hr>
                                </>
                                :
                                <>

                                    <h5 style={{ color: '#ffffff', textAlign: 'center', marginTop: '50px' }}>{detailsName}</h5>
                                    <hr style={{ color: '#ffffff' }}></hr>
                                </>
                        }
                        {
                            detailsName === '' ?
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>We are a banking software and finance app development company that offers reliable custom banking software solutions for all - retail, commercial, investment banks across the globe. With us, banks get a hack-proof environment, a performance-based microservice architecture, and a product that is a result of user-driven agile and DevOps methodologies.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Our custom accounting management software solutions do a lot more than manage just day-to-day financial transactions. They are designed to manage financial information, such as immovable assets, liabilities, cash assets, and more, as per your business needs.</p>
                                </> 
                                :
                                detailsName === 'Banking & Finance' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>We are a banking software and finance app development company that offers reliable custom banking software solutions for all - retail, commercial, investment banks across the globe. With us, banks get a hack-proof environment, a performance-based microservice architecture, and a product that is a result of user-driven agile and DevOps methodologies.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Our custom accounting management software solutions do a lot more than manage just day-to-day financial transactions. They are designed to manage financial information, such as immovable assets, liabilities, cash assets, and more, as per your business needs.</p>
                                </>
                                : 
                                detailsName === 'Education & Learning' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Shape your educational solutions with Softseekers, a leading elearning and educational software development company offering all-in-one customized solutions.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Education software comprises sundry software built for educational purposes. The term encompasses everything pertaining to educational solutions, from learning management systems and assessment software to reference software.</p>
                                </>
                                : 
                                detailsName === 'Fashion & Lifestyle' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>We are leader in Lifestyle Industry Software & Web Application Development, It's making life easier for users and various businesses in establishing.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>We develop unique platforms and Fashion & lifestyle services apps that are able to enhance visitors outcomes offering cost-effective care and real-time data.</p>
                                </>
                                : 
                                detailsName === 'Transportation' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>We offer custom logistics and transportation software development services to innovate supply chain, cut expenses, improve asset tracking.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Our software makes logistics and supply management processes more refined and fast, as well as contribute to the efficiency of how materials, goods, freight, sales orders, inventory, and production are managed.</p>
                                </>
                                :
                                detailsName === 'Healthcare & Medical' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Healthcare software development is a process of creating custom medical apps for state-of-the-art care delivery and cost-efficient internal processes.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Leverage our custom healthcare software development services to improve patient outcomes, provide remote care and automate medical workflows.</p>
                                </>
                                : 
                                detailsName === 'Food & Beverage' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Softseekers is the most trusted food and beverage software development company. Our team of developers expertise in food & beverage software solutions.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Our dedicated developers help with custom software development in the Food and Beverage software industry by delivering unique mobile apps and websites.</p>
                                </>
                                : 
                                detailsName === 'Internet Of Things' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>The Internet of Things (IoT) describes the network of physical objects—“things”—that are embedded with sensors, software, and other technologies for the purpose of connecting and exchanging data with other devices and systems over the internet.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Over the past few years, IoT has become one of the most important technologies of the 21st century. Now that we can connect everyday objects—kitchen appliances, cars, thermostats, baby monitors—to the internet via embedded devices, seamless communication is possible between people, processes, and things.</p>
                                </>
                                : 
                                detailsName === 'Manufacturing' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>We provide custom manufacturing software development services to help manufacturing companies automate workflows and enhance inventory efficiency.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>We specialize in software development company, helping them to achieve better performance and productivity, as well as reduce costs overall. Developing solutions both for back-office and industrial operations, we upgrade your operational processes on every level for digital success.</p>
                                </>
                                : 
                                
                                detailsName === 'Real State' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>We offer custom real estate software development services designed to elevate your project and provide you with a tech-driven competitive advantage.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>We develop technologically advanced applications for the real estate industry. We use AI and complex analysis of large data volumes.</p>
                                </>
                                : 
                                detailsName === 'E-Commerce' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>We provide custom E-commerce websites development services to help manufacturing companies automate workflows and enhance inventory efficiency.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>Ecommerce software development addresses the demand for a digital presence from global manufacturing, distribution and retail businesses. ScienceSoft chooses among development strategies – platform-based, headless or microservices-based ecommerce development – to build the best-suited ecommerce software.</p>
                                </>
                                : 
                                detailsName === 'Gaming' ? 
                                <>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>One solution is to write a Game Design Document, which is typically a detailed guide that describes what your game is and how it will work.</p>
                                    <p style={{ color: '#ffffff', textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>A game design document is the blueprint from which a game is to be built. As such, every single detail necessary to build the game should be addressed.</p>
                                </>
                                :''
                                

                        }

                        <Link to='/letsTalk' style={{textDecoration:'none'}}>
                            <Button className='mx-auto d-block' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', marginLeft: '20px', borderRadius: '30px', border: 'none', marginTop: '10px', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} >Know more</Button>
                        </Link>
                    </Col>
                    <Col md={6}>
                    <div className='industrybg mx-auto d-block'></div>
                    <div className='industrytxt'>
                        <Row>

                            <Col>
                            {
                                detailsName === 'Banking & Finance' ? 
                                 <>
                                <div onClick={() => detailsHandle('Banking & Finance')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaLandmark className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>Banking & Finance</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('Banking & Finance')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaLandmark className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>Banking & Finance</p>
                                </>
                               
                            
                            }
                            </Col>
                            <Col>
                            {
                                detailsName === 'Education & Learning' ? 
                                 <>
                                <div onClick={() => detailsHandle('Education & Learning')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaGraduationCap className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>Education & Learning</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('Education & Learning')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaGraduationCap className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>Education & Learning</p>
                                </>
                               
                            
                            }
                               
                            </Col>
                            <Col>
                            {
                                detailsName === 'Fashion & Lifestyle' ? 
                                 <>
                                <div onClick={() => detailsHandle('Fashion & Lifestyle')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaShoppingCart className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>Fashion & Lifestyle</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('Fashion & Lifestyle')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaShoppingCart className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>Fashion & Lifestyle</p>
                                </>
                               
                            
                            }
                               
        
                            </Col>
                            <Col>

                            {
                                detailsName === 'Transportation' ? 
                                 <>
                                <div onClick={() => detailsHandle('Transportation')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaPlaneDeparture className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>Transportation</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('Transportation')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaPlaneDeparture className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>Transportation</p>
                                </>
                               
                            
                            }
                               
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                              {
                                detailsName === 'Healthcare & Medical' ? 
                                 <>
                                <div onClick={() => detailsHandle('Healthcare & Medical')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaHandHoldingMedical className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>Healthcare & Medical</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('Healthcare & Medical')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaHandHoldingMedical className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>Healthcare & Medical</p>
                                </>
                               
                            
                            }
                               
                            </Col>
                            <Col>

                              {
                                detailsName === 'Food & Beverage' ? 
                                 <>
                                <div onClick={() => detailsHandle('Food & Beverage')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaMugHot className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>Food & Beverage</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('Food & Beverage')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaMugHot className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>Food & Beverage</p>
                                </>
                               
                            
                            }
                                
                            </Col>
                            <Col>

                            {
                                detailsName === 'Internet Of Things' ? 
                                 <>
                                <div onClick={() => detailsHandle('Internet Of Things')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaMicrochip className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>Internet Of Things</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('Internet Of Things')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaMicrochip className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>Internet Of Things</p>
                                </>
                               
                            
                            }
                                
                            </Col>
                            <Col>
                            {
                                detailsName === 'Manufacturing' ? 
                                 <>
                                <div onClick={() => detailsHandle('Manufacturing')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaTools className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>Manufacturing</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('Manufacturing')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaTools className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>Manufacturing</p>
                                </>
                               
                            
                            }
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            {
                                detailsName === 'Real State' ? 
                                 <>
                                <div onClick={() => detailsHandle('Real State')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaCity className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>Real State</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('Real State')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaCity className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>Real State</p>
                                </>
                               
                            
                            }
                               
                            </Col>
                            <Col>

                            {
                                detailsName === 'E-Commerce' ? 
                                 <>
                                <div onClick={() => detailsHandle('E-Commerce')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaLuggageCart className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>E-Commerce</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('E-Commerce')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaLuggageCart className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>E-Commerce</p>
                                </>
                               
                            
                            }
                               
                            </Col>
                            <Col>

                            {
                                detailsName === 'Gaming' ? 
                                 <>
                                <div onClick={() => detailsHandle('Gaming')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#3498db',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaGamepad className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#3498db', textAlign: 'center', fontSize: '13px' }}>Gaming</p>
                                </>
                            :
                                <>
                                <div onClick={() => detailsHandle('Gaming')} className='mx-auto d-block' style={{ height: '60px', width: '60px', background: '#000',borderRadius:'20px', marginTop: '40px', paddingTop: '10px' }}>
                                    <FaGamepad className='mx-auto d-block' style={{ height: '40px', width: '40px', color: '#d1d8e0' }} />
                                </div>
                                <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '13px' }}>Gaming</p>
                                </>
                               
                            
                            }
                            
                            </Col>

                        </Row>
                       </div>
                    </Col>


                </Row>
            </Container>

        </>
    )
}
