import React from 'react'
import { Container } from 'react-bootstrap'
import AboutUs from './AboutUs'
import Navigation from './Navigation'
import TopHeader from './TopHeader'
import OurServices from './OurServices'
import OurProcess from './OurProcess'
import Footer from './Footer'
import Testimonials from './Testimonials'
import Portfolio from './Portfolio'
import ContactUs from './ContactUs'
import Industry from './Industry'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from '../Images/WhatsApp_Image_2023-01-23_at_4.39.38_PM-removebg-preview.png'

export default function Home() {
  {
    window.scrollTo(0, 0)
}
  return (
    <>
    <div style={{backgroundColor:'#050622'}} >

      {/* <Navigation /> */}
      <TopHeader />
      <AboutUs />
      <OurProcess/>
      <OurServices />
      <Industry />
      <Portfolio/>
      <Testimonials/>
      <ContactUs />
      <Footer />

      <FloatingWhatsApp
                phoneNumber="91-9993045404"
                accountName="Softseekers Infotech"
                allowClickAway
                notification
                notificationDelay={60000}
                avatar={logo}
            />

    </div>
    </>
  )
}
